import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";
import { getAnalytics } from "firebase/analytics";

// From Project Settings > General
const firebaseConfig = {
  apiKey: "AIzaSyD0awJF1u_zCwpE4NC6AO1Yn9f5K0neAcg",
  authDomain: "keep-on-tugging.firebaseapp.com",
  databaseURL: "https://keep-on-tugging.firebaseio.com",
  projectId: "keep-on-tugging",
  storageBucket: "keep-on-tugging.appspot.com",
  messagingSenderId: "316297455897",
  appId: "1:316297455897:web:73581993b50f983876fd88",
  measurementId: "G-RQM4184F2H",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const provider = new GoogleAuthProvider();
export const storage = getStorage(app);
export const fb_analytics = getAnalytics(app);
