import _ from "lodash";
import moment from "moment";
import { setDoc } from "firebase/firestore/lite";
import FirestoreDocument from "./document";
import Logger from "utils/logger";
import TimeUtils from "utils/time";

let IP_ADDR = "unknown";
fetch("https://api.ipify.org?format=json")
  .then((response) => response.json())
  .then((data) => {
    IP_ADDR = data.ip;
    Logger.debug(`Got IP Address: ${IP_ADDR}`);
  })
  .catch((error) => {
    Logger.debug("Failed to get IP address:", error);
  });

export default class Event extends FirestoreDocument {
  static COLLECTION = "events";

  constructor(uid = null, event, params = {}) {
    super();

    this.uid = uid;
    this.event = event;
    this.params = params;

    this.saveCheckpoint();
  }

  get json() {
    return {
      uid: this.uid,
      event: this.event,
      time: moment.utc().format(TimeUtils.FullDateTimeString),
      userAgent: navigator.userAgent,
      ...this.params,
    };
  }

  save() {
    this.id = `${moment
      .utc()
      .format(TimeUtils.FullDateTimeString)} ### ${_.random(1000000)}`;
    setDoc(this.document, { ip: IP_ADDR, ...this.json }).catch(Logger.warn);
  }
}
