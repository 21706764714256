// assets
import {
  IconBrandMyOppo,
  IconBulb,
  IconDownload,
  IconMessageChatbot,
} from "@tabler/icons-react";
import { FeedbackFormURL } from "utils/constants";

const other = {
  id: "about",
  title: "This App",
  type: "group",
  children: [
    {
      id: "philosophy",
      title: "Philosophy",
      type: "item",
      url: "/philosophy",
      icon: IconBulb,
      breadcrumbs: false,
    },
    {
      id: "download",
      title: "Download App",
      type: "item",
      url: "/download",
      icon: IconDownload,
      breadcrumbs: false,
    },
    {
      id: "membership",
      title: "Membership",
      type: "item",
      url: "/membership",
      icon: IconBrandMyOppo,
      visible: false,
    },
    {
      id: "contact",
      title: "Bugs / Requests",
      type: "item",
      url: FeedbackFormURL,
      icon: IconMessageChatbot,
      external: true,
      visible: false,
    },
    {
      id: "email",
      title: "Email Me",
      type: "item",
      url: "mailto:keepontugging@gmail.com",
      icon: IconMessageChatbot,
      external: true,
    },
  ],
};

export default other;
