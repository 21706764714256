import _ from "lodash";
import moment from "moment";
import { deleteField, limit, orderBy, where } from "firebase/firestore/lite";

import FirestoreDocument from "./document";
import TugSession from "./tug_session";
import Snapshots from "./snapshots";
import { extractNumberFromString, isValidString } from "utils/misc";
import { MEMBERSHIP } from "utils/constants";
import Logger from "utils/logger";
import Method from "../method";
import TimeUtils from "utils/time";

export default class User extends FirestoreDocument {
  static COLLECTION = "users";

  constructor(uid = null) {
    super();
    this.id = uid;

    this.bio = null;
    this.dates = {
      joined: moment(),
      lastTugged: null,
      started: moment(),
      streakStart: moment(),
    };
    this.level = {
      start: {
        rci: false,
        value: null,
      },
      current: {
        rci: false,
        value: null,
      },
    };
    this.membershipTier = MEMBERSHIP.TRIAL;
    this.methods = {};
    this.spreadsheet = null;
    this.username = {
      display: null,
      standardized: null,
    };

    this.snapshot = new Snapshots(uid);
    this.saveCheckpoint();
  }

  async activeTugSession() {
    const docs = await FirestoreDocument.queryForDocs(
      TugSession.COLLECTION,
      [where("uid", "==", this.uid), where("inProgress", "==", true), limit(1)],
      `active from ${this.uid}`
    );

    const sessions = _.map(docs, (d) => new TugSession(d.id, d.data()));
    return _.first(sessions);
  }

  async allMethods() {
    const customMethods = _.filter(
      _.map(this.methods, (category, method) =>
        category === "Other" ? method : null
      )
    );
    const userMethods = _.map(customMethods, (m) => new Method({ name: m }));

    return _.uniqBy(_.concat(userMethods, Method.generic()), "name");
  }

  async cacheMethod(method) {
    this.methods[method.publicKey] = method.category;
  }

  get hasAccessToPaidFeatures() {
    return _.includes(
      [MEMBERSHIP.PAID, MEMBERSHIP.TRIAL, MEMBERSHIP.EXEMPT],
      this.membershipTier
    );
  }

  get incompleteSignup() {
    return _.some([
      !isValidString(this.username.standardized),
      !isValidString(this.username.display),
      _.isEmpty(this.level),
      this.levelStart === null,
      this.levelCurrent === null,
    ]);
  }

  inferCustomMethod(method) {
    // Attempt to see if this method is already defined
    const genericAttempt = Method.get(method);

    // If not, check if the user has a custom method
    if (!genericAttempt.key && _.has(this.methods, method)) {
      const category = _.get(this.methods, method);
      return new Method({ name: method, type: category });
    }

    // On failure, return category "Other"
    return genericAttempt;
  }

  get json() {
    return {
      bio: isValidString(this.bio) ? _.trim(this.bio) : null,
      dates: {
        joined: this.dates.joined.format(TimeUtils.FullDateString),
        lastTugged: this.lastTugged
          ? this.lastTugged.format(TimeUtils.FullDateTimeString)
          : null,
        started: this.dates.started.format(TimeUtils.FullDateString),
        streakStart: this.dates.streakStart.format(TimeUtils.FullDateString),
      },
      email: deleteField(), // Firestore extensions may add this field in automatically
      level: this.level,
      membershipTier: this.membershipTier,
      methods: this.methods,
      spreadsheet: isValidString(this.spreadsheet) ? this.spreadsheet : null,
      username: this.username,
    };
  }

  async lastMethod() {
    const docs = await FirestoreDocument.queryForDocs(
      TugSession.COLLECTION,
      [where("uid", "==", this.uid), orderBy("date", "desc"), limit(1)],
      `last method from ${this.uid}`
    );

    if (docs.length === 0) {
      return new Method();
    }
    const method = _.get(_.first(docs).data(), "method", {});
    return Method.get(method);
  }

  get lastTugged() {
    return this.dates.lastTugged ? moment(this.dates.lastTugged) : null;
  }

  set lastTugged(date) {
    if (this.lastTugged === null || this.lastTugged.isBefore(date)) {
      this.dates["lastTugged"] = date.format(TimeUtils.FullDateTimeString);
    }
  }

  get levelCurrent() {
    if (_.get(this.level, ["current", "value"], null) === null) {
      return null;
    }
    return `${this.level.current.rci ? "R" : ""}CI-${_.get(
      this.level,
      ["current", "value"],
      0
    )}`;
  }

  set levelCurrent(string) {
    this.level.current = {
      rci: _.startsWith(string, "RCI"),
      value: extractNumberFromString(string),
    };
  }

  get levelStart() {
    if (_.get(this.level, ["start", "value"], null) === null) {
      return null;
    }
    return `${this.level.start.rci ? "R" : ""}CI-${_.get(
      this.level,
      ["start", "value"],
      0
    )}`;
  }

  set levelStart(string) {
    this.level.start = {
      rci: _.startsWith(string, "RCI"),
      value: extractNumberFromString(string),
    };
  }

  async load(loadSnapshot = true) {
    const { id, data } = await this.fetch();
    this.id = id;

    this.bio = _.get(data, "bio", "");
    this.dates = {
      joined: moment(_.get(data, ["dates", "joined"])),
      lastTugged: _.get(data, ["dates", "lastTugged"]),
      started: moment(_.get(data, ["dates", "started"])),
      streakStart: moment(_.get(data, ["dates", "streakStart"])),
    };
    this.level = _.get(data, "level", {
      start: {},
      current: {},
    });
    this.membershipTier = _.get(data, "membershipTier", MEMBERSHIP.TRIAL);
    this.methods = _.get(data, "methods", {});
    this.spreadsheet = _.get(data, "spreadsheet");
    this.username = _.get(data, "username", {
      display: null,
      standardized: null,
    });

    if (loadSnapshot) {
      await this.snapshot.fetch();
    }
    this.saveCheckpoint();

    return Promise.resolve();
  }

  get name() {
    return this.username.display;
  }

  get name_original() {
    return this.json_original.username.standardized;
  }

  set name(username) {
    this.username.display = _.trim(username);
    this.username.standardized = _.toLower(this.username.display);
  }

  get profilePicture() {
    // TODO handle non-png image files
    return `https://firebasestorage.googleapis.com/v0/b/keep-on-tugging.appspot.com/o/profile%2F${this.uid}?alt=media`;
  }

  static get genericProfilePicture() {
    return `https://firebasestorage.googleapis.com/v0/b/keep-on-tugging.appspot.com/o/profile%2Fanteater.png?alt=media`;
  }

  async purgeImported() {
    const docs = await FirestoreDocument.queryForDocs(
      TugSession.COLLECTION,
      [where("uid", "==", this.uid), where("imported", "==", true)],
      `all imported for ${this.uid}`
    );

    await Promise.all(
      _.map(docs, (d) => {
        return new TugSession(d.id, d.data()).delete();
      })
    );
    await this.snapshot.recalculate();
    await this.save();
  }

  static async Search() {
    const docs = await FirestoreDocument.queryForDocs(
      User.COLLECTION,
      [where("username.display", ">", "")],
      `search users`
    );

    const users = _.map(docs, (d) => new User(d.id, d.data()));
    await Promise.all(_.map(users, (u) => u.load(false)));
    return _.orderBy(
      users,
      (u) => (u.lastTugged ? u.lastTugged.valueOf() : -1),
      "desc"
    );
  }

  setLevel(date, value, rci = false) {
    this.level[date].rci = rci;
    this.level[date].value = value;
  }

  /**
   * @param {moment.Moment} date
   */
  set started(date) {
    if (date.isBefore(this.dates.started)) {
      Logger.debug(`Identified new start date for user`);
      this.dates.started = date;
    }
  }

  get streak() {
    if (!this.dates.streakStart) {
      return 0;
    }
    const today = moment();
    if (!this.lastTugged || Math.abs(this.lastTugged.diff(today, "day")) > 1) {
      return 0;
    }
    return (
      this.lastTugged
        .clone()
        .startOf("day")
        .diff(this.dates.streakStart.clone().startOf("day"), "days") + 1
    );
  }

  get streakInDanger() {
    if (!this.lastTugged) {
      return false;
    }
    const today = moment();
    return !this.lastTugged.isSame(today, "day");
  }

  async streakRecalculate(date) {
    // Use moment.js to handle dates
    const startOfDay = moment(date)
      .startOf("day")
      .format(TimeUtils.FullDateTimeString);
    const endOfDay = moment(date)
      .endOf("day")
      .format(TimeUtils.FullDateTimeString);

    const docs = await FirestoreDocument.queryForDocs(
      TugSession.COLLECTION,
      [
        where("uid", "==", this.id),
        where("date", ">=", startOfDay),
        where("date", "<=", endOfDay),
        limit(1),
      ],
      `query streak for ${date.format("YYYY-MM-DD")}`
    );

    if (!docs.length) {
      // No session found, return the next date
      return moment(date).add(1, "day");
    } else {
      // Session found, call the function with the previous day
      const previousDay = moment(date).subtract(1, "day");
      return this.streakRecalculate(previousDay);
    }
  }

  async streakSave() {
    // Update last tugged
    const docs = await FirestoreDocument.queryForDocs(
      TugSession.COLLECTION,
      [where("uid", "==", this.id), orderBy("date", "desc"), limit(1)],
      `query last tugged`
    );
    if (!docs.length) {
      this.dates.lastTugged = null;
    } else {
      const doc = _.first(docs);
      const session = new TugSession(doc.id, doc.data());
      this.dates.lastTugged = session.end.format(TimeUtils.FullDateTimeString);
    }

    // Update streak
    const yesterday = moment().subtract(1, "day");
    this.dates.streakStart = await this.streakRecalculate(yesterday);

    // Save changes
    this.save();
  }

  get uid() {
    return this.id;
  }

  static async Usernames() {
    const docs = await FirestoreDocument.queryForDocs(
      User.COLLECTION,
      [],
      "existing"
    );

    return _.filter(
      _.map(docs, (d) => _.get(d.data(), ["username", "standardized"]))
    );
  }

  static async UsernameToUID(username) {
    const docs = await FirestoreDocument.queryForDocs(
      User.COLLECTION,
      [where("username.standardized", "==", _.toLower(username)), limit(1)],
      `lookup (${username})`
    );

    const uid = _.get(_.first(docs), "id");
    if (!uid) {
      throw new Error(`User (${username}) not found`);
    }
    return uid;
  }
}
