import _ from "lodash";
import { logEvent, setUserId } from "firebase/analytics";

import { DemoUserID, HomePage } from "utils/constants";
import { isValidString } from "utils/misc";
import Logger from "../../utils/logger";
import { fb_analytics } from "./config";
import CurrentSession from "data/user_session";
import Event from "./event";

class AnalyticsWrapper {
  constructor() {
    this.authenticatedUser = null;
    this.lastHoverMarkerTracked = null;
  }

  get baseJson() {
    const data = {
      inDemoMode: CurrentSession.inDemoMode,
    };
    if (!CurrentSession.inDemoMode) {
      data.membershipTier = CurrentSession.user.membershipTier;
    }
    return _.cloneDeep(data);
  }

  event(name, params) {
    this.setUserID();

    Logger.debug(`[FS-A] Event: ${name}`);
    this.track(name, params);
  }

  linkClicked(key, link) {
    this.setUserID();

    Logger.debug(`[FS-A] External link clicked: ${key}`);
    this.track("click_link", { title: key, link: link });
  }

  pageView() {
    this.setUserID();

    let page = _.replace(window.location.pathname, "/", "");
    if (!isValidString(page)) {
      page = HomePage;
    }
    Logger.debug(`[FS-A] Page View: ${page}`);
    this.track("view_page", {
      page: page,
      fullUrl: window.location.href,
    });
  }

  setUserID() {
    if (this.authenticatedUser) {
      return;
    }

    if (_.get(CurrentSession.authenticatedUser, "uid")) {
      Logger.debug(`[FS-A] Setting user ID`);
      this.authenticatedUser = CurrentSession.authenticatedUser.uid;
      setUserId(fb_analytics, this.authenticatedUser);
    }
  }

  throttleChartHover(event, params, key) {
    if (key === this.lastHoverMarkerTracked) {
      return;
    }
    this.lastHoverMarkerTracked = key;
    this.event(event, params);
  }

  track(event, params) {
    const uid = _.get(CurrentSession.authenticatedUser, "uid");
    const data = { ...this.baseJson, ...params };
    if (Logger.inDebugMode() || uid === DemoUserID) {
      Logger.debug(data);
      return;
    }
    // Log to official Firebase Analytics service
    logEvent(fb_analytics, event, data);
    // Log to Firestore collection just in case, because Firebase Analytics feels finnicky
    const FSEvent = new Event(uid, event, data);
    FSEvent.save();
  }
}

const FirebaseAnalytics = new AnalyticsWrapper();
export default FirebaseAnalytics;
