// assets
import {
  IconBarbell,
  IconPhotoHexagon,
  IconHistory,
  IconTargetArrow,
  IconCloudUpload,
  IconDatabase,
  IconFileZip,
  IconUser,
  IconSpyOff,
} from "@tabler/icons-react";

const main = {
  id: "main",
  title: "Your Journey",
  type: "group",
  children: [
    {
      id: "tug",
      title: "Tug",
      type: "item",
      url: "/tug",
      icon: IconBarbell,
      breadcrumbs: false,
    },
    {
      id: "goals",
      title: "Goals [Planned]",
      type: "item",
      url: "/goals",
      icon: IconTargetArrow,
      breadcrumbs: false,
      disabled: true,
      visible: false,
    },
    {
      id: "history",
      title: "History",
      type: "item",
      url: "/history",
      icon: IconHistory,
      breadcrumbs: false,
    },
    {
      id: "albums",
      title: "Albums",
      type: "item",
      url: "/albums",
      icon: IconPhotoHexagon,
      breadcrumbs: false,
    },
    {
      id: "data",
      title: "Your Data",
      type: "collapse",
      icon: IconDatabase,
      breadcrumbs: false,
      children: [
        {
          id: "import",
          title: "Import",
          type: "item",
          url: "/import",
          icon: IconCloudUpload,
          breadcrumbs: false,
        },
        {
          id: "export",
          title: "Export [Planned]",
          type: "item",
          url: "/export",
          icon: IconFileZip,
          breadcrumbs: false,
          disabled: true,
          visible: false,
        },
        {
          id: "profile",
          title: "Profile",
          type: "item",
          url: "/profile",
          icon: IconUser,
          breadcrumbs: false,
        },
        {
          id: "privacy",
          title: "Your Privacy",
          type: "item",
          url: "/privacy",
          icon: IconSpyOff,
        },
      ],
    },
  ],
};

export default main;
