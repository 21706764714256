// material-ui
import { Typography } from "@mui/material";

// project imports
import MainCard from "components/MainCard";

// ==============================|| WIP PAGE ||============================== //

const WIPPage = () => {
  return (
    <MainCard title="Work in Progress">
      <Typography variant="body2">
        This page is still in development. Please check back later!
      </Typography>
    </MainCard>
  );
};

export default WIPPage;
