import moment from "moment";

export default class Timer {
  constructor(unixTimestamp = null) {
    this.startTime = unixTimestamp
      ? moment.utc(unixTimestamp).utcOffset(0, true)
      : null;
  }

  get elapsed() {
    if (!this.startTime) {
      return 0;
    }
    const now = moment().utcOffset(0, true);
    return now.diff(this.startTime, "milliseconds");
  }

  start() {
    this.startTime = moment().utcOffset(0, true);
  }

  stop() {
    this.startTime = null;
  }
}
