export const DemoUserID = "6d6uUgIUyFSO055Fmmqw1nk1R1L2";
export const FeedbackFormURL = "https://forms.gle/dB54KwqFvMaCkKpW9";
export const HomePage = "philosophy";

export const MEMBERSHIP = {
  UNPAID: "unpaid",
  TRIAL: "trial",
  PAID: "paid",
  EXEMPT: "exempt",

  price: 4.99,
};
