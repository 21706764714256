import {
  blueGrey,
  lightBlue,
  lightGreen,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";

export const MethodCategories = {
  DIY: "DIY / 3D Printed",
  DT: "Dual Tension",
  Inflation: "Inflation",
  Manual: "Manual",
  Other: "Other",
  Retaining: "Retaining",
  Taping: "Taping",
  Traction: "Traction",
  Traditional: "Traditional Tugger",
  Weights: "Weights",
};

export const MethodColors = {};
MethodColors[MethodCategories.DIY] = pink;
MethodColors[MethodCategories.DT] = red;
MethodColors[MethodCategories.Inflation] = lightGreen;
MethodColors[MethodCategories.Manual] = lightBlue;
MethodColors[MethodCategories.Other] = yellow;
MethodColors[MethodCategories.Retaining] = yellow;
MethodColors[MethodCategories.Taping] = orange;
MethodColors[MethodCategories.Traction] = blueGrey;
MethodColors[MethodCategories.Traditional] = purple;
MethodColors[MethodCategories.Weights] = teal;

export const AllMethods = [
  {
    key: "4restore",
    name: "4restore",
    type: MethodCategories.DT,
  },
  {
    key: "Airforce",
    name: "Airforce",
    type: MethodCategories.Inflation,
  },
  {
    key: "Andres_Method",
    name: "Andre's Method",
    type: MethodCategories.Manual,
  },
  {
    key: "CAT_II_Q",
    name: "CAT II Q",
    type: MethodCategories.DT,
  },
  {
    key: "Canister",
    name: "Canister Method",
    type: MethodCategories.Taping,
  },
  {
    key: "DILE_Insert",
    name: "DILE Insert",
    type: MethodCategories.DT,
  },
  {
    key: "DTR",
    name: "Dual Tension Rod (DTR)",
    type: MethodCategories.DT,
  },
  {
    key: "DTR_w_Air",
    name: "DTR with Direct Air",
    type: MethodCategories.Inflation,
  },
  {
    key: "DTR_w_Indirect_Air",
    name: "DTR with Indirect Air",
    type: MethodCategories.Inflation,
  },
  {
    key: "DTR_w_Weights",
    name: "DTR with weights",
    type: MethodCategories.Weights,
  },
  {
    key: "FIT_v2",
    name: "FIT v2",
    type: MethodCategories.Inflation,
  },
  {
    key: "FIT_v3",
    name: "FIT v3",
    type: MethodCategories.Inflation,
  },
  {
    key: "FMD",
    name: "Foreskin Mitosis Device (FMD)",
    type: MethodCategories.Traditional,
  },
  {
    key: "Foreballs",
    name: "Foreballs",
    type: MethodCategories.Weights,
  },
  {
    key: "Foreclip",
    name: "Foreclip",
    type: MethodCategories.Traditional,
  },
  {
    key: "Forenew",
    name: "Forenew",
    type: MethodCategories.DT,
  },
  {
    key: "Foreskinned_Air",
    name: "Foreskinned Air",
    type: MethodCategories.Inflation,
  },
  {
    key: "Foreskinned_Gravity",
    name: "Foreskinned Gravity",
    type: MethodCategories.Weights,
  },
  {
    key: "Foreskinned_Tower",
    name: "Foreskinned Tower",
    type: MethodCategories.DT,
  },
  {
    key: "Foreskinned_Workhorse",
    name: "Foreskinned Workhorse",
    type: MethodCategories.Traditional,
  },
  {
    key: "HART",
    name: "Happy Aardvark (HART)",
    type: MethodCategories.DT,
  },
  {
    key: "HyperRestore_Direct_Air",
    name: "HyperRestore Direct Air",
    type: MethodCategories.Inflation,
  },
  {
    key: "HyperRestore_Original",
    name: "HyperRestore Original",
    type: MethodCategories.Inflation,
  },
  {
    key: "MTC",
    name: "Modified T-Taping/Cross-Taping (MTC)",
    type: MethodCategories.Taping,
  },
  {
    key: "Mantor_Restorer",
    name: "Mantor Restorer",
    type: MethodCategories.DT,
  },
  {
    key: "MM1",
    name: "Manual Method 1 (MM1)",
    type: MethodCategories.Manual,
  },
  {
    key: "MM2",
    name: "Manual Method 2 (MM2)",
    type: MethodCategories.Manual,
  },
  {
    key: "MM3",
    name: "Manual Method 3 (MM3)",
    type: MethodCategories.Manual,
  },
  {
    key: "MM4",
    name: "Manual Method 4 (MM4)",
    type: MethodCategories.Manual,
  },
  {
    key: "MM5",
    name: "Manual Method 5 (MM5)",
    type: MethodCategories.Manual,
  },
  {
    key: "MM6",
    name: "Manual Method 6 (MM6)",
    type: MethodCategories.Manual,
  },
  {
    key: "MM7",
    name: "Manual Method 7 (MM7)",
    type: MethodCategories.Manual,
  },
  {
    key: "MetaMoreFore",
    name: "MetaMoreFore",
    type: MethodCategories.DIY,
  },
  {
    key: "MySkinClamp",
    name: "MySkinClamp",
    type: MethodCategories.Other,
  },
  {
    key: "o-rings",
    name: "O-Rings",
    type: MethodCategories.Retaining,
  },
  {
    key: "PUD",
    name: "The PUD",
    type: MethodCategories.Weights,
  },
  {
    key: "PeckerPacker",
    name: "PeckerPacker",
    type: MethodCategories.Inflation,
  },
  {
    key: "Penimaster_Pro",
    name: "Penimaster Pro",
    type: MethodCategories.Traction,
  },
  {
    key: "Retaining",
    name: "Retaining",
    type: MethodCategories.Retaining,
  },
  {
    key: "Retainer",
    name: "Retainer",
    type: MethodCategories.Retaining,
  },
  {
    key: "Reverse_Taping_Method",
    name: "Reverse Taping Method (RTM)",
    type: MethodCategories.Taping,
  },
  {
    key: "Stamen_D",
    name: "Stamen D",
    type: MethodCategories.Weights,
  },
  {
    key: "Stamen_E",
    name: "Stamen E",
    type: MethodCategories.DT,
  },
  {
    key: "Stealth_Retainers",
    name: "Stealth Retainers Weight Kits",
    type: MethodCategories.Weights,
  },
  {
    key: "SuperCanister",
    name: "SuperCanister",
    type: MethodCategories.Taping,
  },
  {
    key: "T-Taping",
    name: "T-Taping",
    type: MethodCategories.Taping,
  },
  {
    key: "TLC_Air",
    name: "TLC Air Devices",
    type: MethodCategories.Inflation,
  },
  {
    key: "TLC_Hanger",
    name: "TLC Hanger",
    type: MethodCategories.Weights,
  },
  {
    key: "TLC_Tugger",
    name: "TLC Tugger",
    type: MethodCategories.Traditional,
  },
  {
    key: "TLC-X",
    name: "TLC-X",
    type: MethodCategories.DT,
  },
  {
    key: "Tug_Ahoy",
    name: "Tug Ahoy",
    type: MethodCategories.Other,
  },
  {
    key: "o-rings",
    name: "O-Rings",
    type: MethodCategories.Other,
  },
];
