// project imports
import HeaderCTA from "../HeaderCTA";

const SignupCTA = ({ onSignIn }) => {
  return (
    <HeaderCTA
      message={[
        "For years I've dreamed of a restoration app that was more insightful than spreadsheets and scattered pictures.",
        "Now, there's an app built by a fellow restorer :)",
      ]}
    />
  );
};

export default SignupCTA;
