import top from "./top-level";
import tug from "./tug";
import resources from "./resources";
import about from "./about";
import moment from "moment";

const menuItems = {
  items: [
    top,
    tug,
    about,
    resources,
    {
      id: "copyright",
      title: `© Copyright ${moment().year()}`,
      type: "group",
    },
  ],
};

export default menuItems;
