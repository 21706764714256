import { useState, useRef, useEffect } from "react";

import { useSelector } from "react-redux";

import PerfectScrollbar from "react-perfect-scrollbar";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Badge,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  IconAlertTriangle,
  IconFlame,
  IconLogout,
  IconMoodSmileBeam,
  IconSparkles,
  IconUser,
} from "@tabler/icons-react";

// project imports
import MainCard from "./MainCard";
import Transitions from "./external/Transitions";
import CurrentSession from "data/user_session";
import useCustomNavigation from "utils/custom_navigation";
import CustomChip from "./Chip";
import TimeUtils from "utils/time";

// ==============================|| PROFILE MENU ||============================== //

const useStyles = makeStyles((theme) => ({
  chip: {
    "& .MuiChip-label": {
      lineHeight: "initial",
      overflow: "visible",
    },
    border: "none", // This removes the border
  },
}));

const HeaderDropdownUser = ({ onSignOut }) => {
  const theme = useTheme();
  const classes = useStyles();

  const customization = useSelector((state) => state.customization);
  const navigate = useCustomNavigation();

  const [open, setOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [streak, setStreak] = useState(0);
  const [streakInDanger, setStreakInDanger] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    CurrentSession.load().then((user) => {
      setUsername(user.name);
      setProfilePicture(user.profilePicture);
      setStreak(user.streak);
      setStreakInDanger(user.streakInDanger);
    });
  }, [open]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setStreak(CurrentSession.user.streak);
      setStreakInDanger(CurrentSession.user.streakInDanger);
    }, TimeUtils.RefreshRate);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
          paddingRight: 1.5,
        }}
        icon={
          <Avatar
            src={profilePicture}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          >
            <IconUser />
          </Avatar>
        }
        label={
          <Tooltip title="Streak">
            <Typography>{`${Math.max(streak, 0)}d`}</Typography>
          </Tooltip>
        }
        deleteIcon={
          <Badge
            badgeContent={
              streakInDanger ? (
                <IconAlertTriangle
                  size={15}
                  style={{ color: "orange", marginRight: 5 }}
                />
              ) : (
                0
              )
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <IconFlame
              stroke={1.5}
              size="1.5rem"
              color={streak > 0 ? "orangered" : "gray"}
            />
          </Badge>
        }
        onDelete={() => {}}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
        className={classes.chip}
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2, paddingBottom: 0 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Let's Get Tugging!</Typography>
                      </Stack>
                      <Typography variant="overline">{username}</Typography>
                    </Stack>
                    <Divider />
                  </Box>
                  <PerfectScrollbar
                    style={{
                      height: "100%",
                      maxHeight: "calc(100vh - 250px)",
                      overflowX: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        paddingLeft: 2,
                        paddingRight: 2,
                        textAlign: "center",
                      }}
                    >
                      <CustomChip
                        label="Thank you for being a member!"
                        icon={<IconSparkles />}
                        trailingIcon={<IconMoodSmileBeam />}
                        color="success"
                        style={{ marginTop: 5 }}
                      />
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          onClick={(e) => {
                            navigate(`profile`);
                            handleClose(e);
                          }}
                        >
                          <ListItemIcon>
                            <IconUser stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Profile</Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          onClick={(e) => {
                            onSignOut();
                            handleClose(e);
                          }}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Logout</Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default HeaderDropdownUser;
