import FirebaseAnalytics from "data/firestore/analytics";
import { forwardRef } from "react";
import Link from "@mui/material/Link";

import useCustomNavigation from "utils/custom_navigation";

const CustomLink = forwardRef((props, ref) => {
  const navigate = useCustomNavigation();
  const { title, children, external, dest, style } = props;
  if (external) {
    return (
      <Link
        ref={ref}
        href={dest}
        target="_blank"
        rel="noreferrer"
        style={style}
        onClick={() => FirebaseAnalytics.linkClicked(title, dest)}
        underline="hover"
      >
        {children}
      </Link>
    );
  }
  return (
    <Link
      ref={ref}
      to={`../${dest}`}
      onClick={() => navigate(dest)}
      style={style}
      underline="hover"
    >
      {children}
    </Link>
  );
});

export default CustomLink;
