// assets
import {
  IconBooks,
  IconBrandReddit,
  IconDeviceSim1,
  IconDeviceSim2,
  IconRuler,
  IconSocial,
  IconUsers,
  IconWand,
} from "@tabler/icons-react";

const resources = {
  id: "resources",
  title: "Resources",
  type: "group",
  children: [
    {
      id: "wiki",
      title: "Getting Started",
      type: "item",
      url: "https://www.reddit.com/r/foreskin_restoration/wiki/index",
      icon: IconBooks,
      breadcrumbs: false,
      external: true,
    },
    {
      id: "methods",
      title: "Methods",
      type: "item",
      url: "https://www.reddit.com/r/foreskin_restoration/wiki/methods",
      icon: IconWand,
      breadcrumbs: false,
      external: true,
    },
    {
      id: "ci",
      title: "Coverage Index Scale",
      type: "collapse",
      icon: IconRuler,
      children: [
        {
          id: "rci",
          title: "Real Coverage Index",
          type: "item",
          url: "https://www.restoringforeskin.org/public/foreskin-restoration-real-coverage-index-rci",
          icon: IconDeviceSim1,
          breadcrumbs: false,
          external: true,
        },
        {
          id: "ci",
          title: "Original Coverage Index",
          type: "item",
          url: "https://www.restoringforeskin.org/coverage-index/CI-chart.htm",
          icon: IconDeviceSim2,
          breadcrumbs: false,
          external: true,
        },
      ],
    },
    {
      id: "forums",
      title: "Community Forums",
      type: "collapse",
      icon: IconUsers,
      children: [
        {
          id: "ci",
          title: "Restoring Foreskin",
          type: "item",
          url: "https://www.restoringforeskin.org/",
          icon: IconSocial,
          breadcrumbs: false,
          external: true,
        },
        {
          id: "reddit",
          title: "Reddit",
          type: "item",
          url: "https://www.reddit.com/r/foreskin_restoration/",
          icon: IconBrandReddit,
          breadcrumbs: false,
          external: true,
        },
      ],
    },
  ],
};

export default resources;
