import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MENU_OPEN } from "store/actions";
import FirebaseAnalytics from "data/firestore/analytics";
import { HomePage } from "./constants";

function useCustomNavigation() {
  const theirNavigate = useNavigate();
  const dispatch = useDispatch();

  const navigate = (path, appendage = "", replace = false) => {
    // Dispatch the action with a predefined type
    dispatch({
      type: MENU_OPEN,
      id: path === "/" || path === "" ? HomePage : path,
    });

    // Navigate to the specified path
    theirNavigate(`/${path}${appendage}`, { replace: replace });

    if (!replace) {
      FirebaseAnalytics.pageView();
    }
  };

  return navigate;
}

export default useCustomNavigation;
