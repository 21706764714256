import _ from "lodash";

export function isValidString(str) {
  // Check if the string is not null or undefined
  if (_.isNil(str) || !_.isString(str)) {
    return false;
  }

  // Trim the string and then check if it's empty
  return !_.isEmpty(_.trim(str));
}

export function setTabTitle(title) {
  if (!title) {
    document.title = `Keep on Tugging`;
  } else {
    document.title = `${title} - Keep on Tugging`;
  }
}

export function extractNumberFromString(string) {
  const match = string.match(/\d+/); // Find consecutive digits
  return match ? parseInt(match[0], 10) : null; // Return the number if found
}
