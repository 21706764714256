import _ from "lodash";

// material-ui
import ReactMarkdown from "react-markdown";
import { styled } from "@mui/material/styles";
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";

// project imports
import AnimateButton from "./external/AnimateButton";

// styles
const CardStyle = styled(Card)(({ theme }) => ({
  background: theme.palette.warning.light,
  marginBottom: "16px",
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "19px solid ",
    borderColor: theme.palette.warning.main,
    borderRadius: "50%",
    top: "65px",
    right: "-150px",
    opacity: 0.25,
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "3px solid ",
    borderColor: theme.palette.warning.main,
    borderRadius: "50%",
    top: "145px",
    right: "-70px",
    opacity: 0.25,
  },
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const HeaderCTA = ({ title, message, button }) => {
  return (
    <CardStyle style={{ maxWidth: 350, marginTop: 5 }}>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Grid item>
            {_.map(message, (m, idx) => (
              <ReactMarkdown key={idx} children={m} />
            ))}
          </Grid>
          <Grid item>
            <Stack direction="row">
              <AnimateButton>{button}</AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </CardStyle>
  );
};

export default HeaderCTA;
