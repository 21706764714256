import _ from "lodash";
import moment from "moment";
import { limit, orderBy, where } from "firebase/firestore/lite";

import FirestoreDocument from "./document";
import Timer from "../timer";
import CurrentSession from "data/user_session";
import { isValidString } from "utils/misc";
import TimeUtils from "utils/time";

export default class TugSession extends FirestoreDocument {
  static COLLECTION = "sessions";

  constructor(id = null, data = {}) {
    super();

    this.id = id;
    this.edited = false;

    this.date = moment(_.get(data, "date"));
    this.date_original = this.date.clone();
    this._duration = _.get(data, "duration", 0);
    this.exported = _.get(data, "exported", false);
    this.imported = _.get(data, "imported", false);
    this.inProgress = _.get(data, "inProgress", false);
    this.inProgressTime = _.get(data, "inProgressTime", null);
    this.method = CurrentSession.user.inferCustomMethod(_.get(data, "method"));
    this.notes = _.get(data, "notes", "");
    this.sessions = _.get(data, "sessions", 1);
    this.uid = _.get(data, "uid");

    this.timer = new Timer(this.inProgressTime);
    // Check if original session had a time attached to it (default to true if new session)
    const timeRegex = /(\d{1,2}:\d{1,2}(:\d{1,2})?([ap]m)?)\b/gi;
    this.timeWasExplicitlySet =
      !!_.get(data, "date", "").match(timeRegex) || !this.id;

    this.saveCheckpoint();
  }

  static async fromID(ID) {
    const placeholder = new TugSession(ID);
    const { id, data } = await placeholder.fetch();

    if (!id || !data) {
      throw new Error(`Session not found for ID: ${ID}`);
    }
    return new TugSession(id, data);
  }

  get dateString() {
    return this.date.format("ddd MMM D");
  }

  get dateTimeString() {
    if (this.timeWasExplicitlySet) {
      return this.date.format("ddd MMM D @ h:mma");
    }
    return this.date.format("ddd MMM D");
  }

  get duration() {
    return this._duration + this.timer.elapsed;
  }

  get durationFinal() {
    if (this.inProgress) {
      return -1;
    }
    return this.duration;
  }

  get end() {
    return this.date.clone().add(this.duration, "milliseconds");
  }

  set end(date) {
    this._duration = date.diff(this.date);
  }

  static async history(uid, month) {
    // Maximum session duration is up to 24 hours
    const maximumSessionDuration = 23 * 60 * 59 * 1000; // 24 hours in milliseconds

    // Start of the current month without adjustments
    const startOfMonth = moment(month).startOf("month");

    // Adjust the startOfMonth to start from 24 hours before the start of the month
    const adjustedStartOfMonth = startOfMonth
      .clone()
      .subtract(maximumSessionDuration, "milliseconds")
      .format(TimeUtils.FullDateString);

    const startOfNextMonth = moment(month)
      .add(1, "month")
      .startOf("month")
      .format(TimeUtils.FullDateString);

    const docs = await FirestoreDocument.queryForDocs(
      TugSession.COLLECTION,
      [
        where("uid", "==", uid),
        where("date", ">=", adjustedStartOfMonth),
        where("date", "<", startOfNextMonth),
      ],
      `${adjustedStartOfMonth} to ${startOfNextMonth} for ${uid}`
    );

    // Filter sessions to exclude those that start and end before the actual start of the month
    const filteredDocs = docs.filter((doc) => {
      const sessionEnd = moment(doc.data().date).add(
        doc.data().duration,
        "milliseconds"
      );
      return sessionEnd.isSameOrAfter(startOfMonth);
    });

    return _.map(filteredDocs, (d) => new TugSession(d.id, d.data()));
  }

  get json() {
    return {
      date: this.date.format(
        this.timeWasExplicitlySet
          ? TimeUtils.FullDateTimeString
          : TimeUtils.FullDateString
      ),
      duration: this._duration,
      exported: this.exported,
      imported: this.imported,
      inProgress: this.inProgress,
      inProgressTime: this.inProgressTime,
      method: this.method.publicKey,
      notes: _.trim(this.notes),
      sessions: this.sessions,
      uid: this.uid,
    };
  }

  needsToBeExported() {
    this.exported = false;
  }

  static async recent(uid) {
    const docs = await FirestoreDocument.queryForDocs(
      TugSession.COLLECTION,
      [where("uid", "==", uid), orderBy("date", "desc"), limit(10)],
      `recent sessions for ${uid}`
    );

    return _.map(docs, (d) => new TugSession(d.id, d.data()));
  }

  reset() {
    this._duration = 0;
    this.timer.stop();
    this.inProgress = false;
    this.inProgressTime = null;
  }

  async save(recalculateSnapshot = true) {
    if (this.validationErrors.length) {
      throw _.first(this.validationErrors);
    }

    await super.save();

    CurrentSession.user.started = this.date;
    CurrentSession.user.lastTugged = this.end;
    if (recalculateSnapshot) {
      await CurrentSession.user.snapshot.recalculateForDates([
        this.date,
        this.date_original,
      ]);
      CurrentSession.user.cacheMethod(this.method);
      await CurrentSession.user.save();
    }
    this.date_original = this.date.clone();
  }

  startTimer() {
    // If the user hasn't started tugging and was sitting on the Tug page for awhile, reset start time
    if (this.duration === 0) {
      this.date = moment();
    }

    this.timer.start();
    this.inProgress = true;
    this.inProgressTime = this.timer.startTime.valueOf();
  }

  stopTimer() {
    this._duration += this.timer.elapsed;

    this.timer.stop();
    this.inProgress = false;
    this.inProgressTime = null;
  }

  get validationErrors() {
    const errors = [];
    if (!this.date.isValid) {
      errors.push(new Error("Invalid date"));
    }
    if (!isValidString(this.method.name)) {
      errors.push(new Error("Invalid method name"));
    } else if (this.duration <= 0) {
      errors.push(new Error("Tug duration must be set"));
    } else if (this.sessions < 1) {
      errors.push(new Error("Number of sessions should at least be 1"));
    } else if (!this.date.isValid()) {
      errors.push(new Error("Invalid date"));
    }
    return errors;
  }
}
