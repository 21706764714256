import { forwardRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Chip } from "@mui/material";

const CustomChip = forwardRef(({ trailingIcon, trailingFn, ...props }, ref) => {
  const theme = useTheme();
  const { color, sx = {} } = props;

  const chipSX = {
    minHeight: 28,
    padding: "0 6px",
    ...sx,
  };
  const map = {
    info: {
      ...chipSX,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    success: {
      ...chipSX,
      color: theme.palette.success.dark,
      backgroundColor: theme.palette.success.light,
    },
    warning: {
      ...chipSX,
      color: theme.palette.warning.semiDark,
      backgroundColor: theme.palette.warning.light,
    },
    error: {
      ...chipSX,
      color: theme.palette.orange.dark,
      backgroundColor: theme.palette.orange.light,
      marginRight: "5px",
    },
  };

  return (
    <Chip
      ref={ref}
      {...props}
      sx={color ? map[color] : sx}
      deleteIcon={trailingIcon}
      onDelete={trailingFn ? trailingFn : trailingIcon ? () => {} : null}
    />
  );
});

export default CustomChip;
