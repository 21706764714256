import _ from "lodash";
import moment from "moment";
import TimeUtils from "utils/time";

export default class Snapshot {
  constructor(data = {}) {
    this.days = _.get(data, "days", 0);
    this.duration = _.get(data, "duration", 0);
    this.sessions = _.get(data, "sessions", 0);

    this.dates = new Set();
    this.methods = _.get(data, "methods", {});
  }

  get json() {
    return {
      days: this.days,
      duration: this.duration,
      sessions: this.sessions,

      methods: this.methods,
    };
  }

  track(session) {
    this.dates.add(moment(session.date).format(TimeUtils.FullDateString));
    this.days = this.dates.size;
    this.duration += session.duration;
    this.sessions += session.sessions;

    const keyToSearch = session.method.key || session.method.name;
    if (!_.has(this.methods, keyToSearch)) {
      this.methods[keyToSearch] = 0;
    }
    this.methods[keyToSearch] += session.duration;
  }
}
