// assets
import { IconMapRoute, IconMoodSearch } from "@tabler/icons-react";

const top = {
  id: "top",
  type: "group",
  children: [
    {
      id: "progress",
      title: "Progress",
      type: "item",
      url: "/progress",
      icon: IconMapRoute,
      breadcrumbs: false,
    },
    {
      id: "search",
      title: "Fellow Restorers",
      type: "item",
      url: "/search",
      icon: IconMoodSearch,
      breadcrumbs: false,
    },
  ],
};

export default top;
