import _ from "lodash";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
} from "firebase/firestore/lite";
import { db } from "./config";
import Logger from "utils/logger";
import CurrentSession from "data/user_session";

const FetchFromCache = _.memoize(
  async (collection, id) => {
    Logger.debug(`[FS-DB] Fetching document from ${collection} (${id})`);

    const docRef = doc(db, collection, id);
    const response = await getDoc(docRef);
    return {
      id: response.id,
      data: response.data(),
    };
  },
  (c, i) => `${c} ${i}`
);

const QueryFromCache = _.memoize(
  async (collectionName, where, key) => {
    Logger.debug(`[FS-DB] Querying from ${collectionName} (${key})`);

    const q = await getDocs(query(collection(db, collectionName), ...where));
    return q.docs;
  },
  (c, w, key) => key
);

export default class FirestoreDocument {
  get collection() {
    return collection(db, this.constructor.COLLECTION);
  }

  get document() {
    return this.id ? doc(db, this.constructor.COLLECTION, this.id) : null;
  }

  async delete() {
    if (this.document) {
      Logger.debug(
        `[FS-DB] Deleting resource: ${this.constructor.COLLECTION} (${this.id})`
      );
      FirestoreDocument.resetCache();
      return deleteDoc(this.document);
    }
  }

  async fetch() {
    return await FetchFromCache(this.constructor.COLLECTION, this.id);
  }

  get changesMade() {
    return !_.isEqual(this.json, this.json_original);
  }

  static async queryForDocs(collectionName, where = [], key) {
    return await QueryFromCache(collectionName, where, key);
  }

  static resetCache() {
    Logger.debug("[FS-DB] Cache has been reset");
    FetchFromCache.cache.clear();
    QueryFromCache.cache.clear();
  }

  async save(preserveExistingDoc = true) {
    // Only save if changes were explicitly made OR this is a new (imported) doc
    if (!this.changesMade && this.id) {
      return Promise.resolve();
    }
    if (
      !CurrentSession.authenticationStrictlyMatches(CurrentSession.user.uid) ||
      (!!this.uid && this.uid !== CurrentSession.user.uid)
    ) {
      throw new Error("You don't have permission to save this change");
    }

    Logger.debug(`[FS-DB] Saving document for ${this.constructor.COLLECTION}`);
    FirestoreDocument.resetCache();

    if (this.document) {
      return setDoc(this.document, this.json, {
        merge: preserveExistingDoc,
      }).then(() => {
        this.saveCheckpoint();
      });
    } else {
      this.uid = CurrentSession.user.uid;
      return addDoc(this.collection, this.json).then((docRef) => {
        this.id = docRef.id;
        this.saveCheckpoint();
      });
    }
  }

  saveCheckpoint() {
    this.json_original = _.cloneDeep(this.json);
  }
}
