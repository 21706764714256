export default class Logger {
  static inDebugMode() {
    const currentUrl = window.location.href;
    return (
      currentUrl.includes("localhost") ||
      currentUrl.includes("test.") ||
      currentUrl.includes("demo.")
    );
  }

  static debug(msg) {
    if (Logger.inDebugMode()) {
      console.log(msg);
    }
  }

  static transparency(msg) {
    console.log(msg);
  }

  static warn(msg) {
    console.warn(msg);
  }
}
