import _ from "lodash";
import { signInWithPopup, signOut } from "firebase/auth";

import { auth, provider } from "./firestore/config";
import User from "./firestore/user";
import Logger from "../utils/logger";
import { DemoUserID } from "utils/constants";

const demoUser = new User(DemoUserID);

class Session {
  constructor() {
    this.authenticatedUser = null;
  }

  authenticationStrictlyMatches(uid) {
    return uid === _.get(this.authenticatedUser, "uid");
  }

  get inDemoMode() {
    // Check if I'm the user
    if (_.get(this.authenticatedUser, "uid") === DemoUserID) {
      return false;
    }
    // If not, do a simple check
    return _.get(this.user, "uid") === DemoUserID;
  }

  get isSignedIn() {
    return !!this.authenticatedUser;
  }

  async load() {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        unsubscribe(); // make sure to only listen once
        if (user && _.has(user, "uid")) {
          // Don't reinitialize authenticated user unless absolutely necessary
          if (!this.authenticatedUser) {
            this.authenticatedUser = new User(_.get(user, "uid"));
          }
          await this.authenticatedUser.load();
          resolve(this.authenticatedUser);
        } else {
          await demoUser.load();
          resolve(demoUser);
        }
      }, reject); // also make sure to reject the Promise if there's an error
    });
  }

  async SignIn() {
    return await signInWithPopup(auth, provider).then((result) => {
      // Signed in..
      Logger.debug("Signed in as user");

      const metadata = result.user.metadata;
      return metadata.creationTime === metadata.lastSignInTime;
    });
  }

  async SignOut() {
    return await signOut(auth);
  }

  get user() {
    return this.authenticatedUser || demoUser;
  }
}

const CurrentSession = new Session();
export default CurrentSession;
